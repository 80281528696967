import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 388.000000 306.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,306.000000) scale(0.100000,-0.100000)">



<path d="M0 1530 l0 -1530 1940 0 1940 0 0 280 0 280 -89 0 c-50 0 -93 4 -96
10 -3 5 1 23 9 39 14 27 14 31 -6 57 -12 16 -25 31 -28 34 -3 3 -25 29 -48 57
-43 51 -44 52 -68 37 -21 -14 -24 -24 -24 -78 0 -43 6 -70 20 -94 35 -58 31
-62 -69 -62 -50 0 -92 4 -95 8 -3 5 6 30 19 55 24 45 25 52 25 254 0 203 -1
209 -25 251 -14 24 -22 48 -19 53 3 5 36 9 75 9 l69 0 0 -181 0 -181 52 39
c86 62 109 85 106 107 -3 19 3 21 56 24 76 4 91 -10 39 -36 -40 -19 -183 -128
-183 -138 0 -4 7 -4 15 0 17 6 18 5 120 -108 61 -68 119 -116 139 -116 3 0 6
554 6 1230 l0 1230 -1940 0 -1940 0 0 -1530z m1598 1261 c-4 -54 -9 -66 -41
-99 -30 -31 -67 -95 -67 -117 0 -15 25 1 98 65 45 39 84 81 88 94 8 32 52 72
89 80 17 4 38 9 47 12 16 5 16 2 -2 -41 -24 -55 -53 -82 -98 -91 -51 -10 -267
-202 -248 -221 9 -10 133 60 161 91 30 33 37 36 89 36 l57 0 -27 -35 c-34 -45
-53 -55 -101 -55 -41 0 -190 -60 -205 -82 -23 -36 -70 -248 -54 -248 14 0 87
118 92 149 9 47 39 89 70 97 13 3 24 10 24 15 0 5 5 9 11 9 16 0 0 -80 -22
-112 -11 -14 -32 -32 -48 -39 -37 -17 -121 -138 -121 -176 0 -39 23 -119 50
-178 l23 -50 8 40 c11 57 11 123 -1 130 -18 11 -11 86 11 121 11 19 24 34 28
34 10 0 31 -62 31 -91 0 -13 -9 -38 -20 -56 -10 -18 -22 -56 -25 -85 -3 -29
-8 -70 -10 -90 -5 -35 -1 -42 65 -112 39 -41 84 -79 100 -84 l28 -10 -29 61
c-15 34 -41 73 -57 87 -16 14 -34 35 -41 47 -13 25 -15 103 -3 103 5 0 26 -15
47 -33 33 -29 38 -39 42 -87 3 -34 18 -84 40 -132 l36 -76 66 -21 c98 -29 116
-32 135 -18 16 12 15 15 -19 42 -21 15 -57 32 -81 36 -49 9 -73 28 -98 77
l-17 32 36 0 c45 0 80 -23 111 -72 13 -21 42 -50 64 -64 l40 -27 41 29 c23 16
52 44 64 64 31 50 50 62 101 70 24 4 44 5 44 3 0 -2 -9 -21 -19 -42 -23 -44
-59 -71 -96 -71 -15 0 -47 -14 -72 -31 -38 -26 -43 -33 -31 -45 11 -11 23 -11
73 0 118 26 136 39 173 119 22 49 32 87 32 119 0 41 5 52 38 88 48 49 62 51
62 6 0 -50 -16 -87 -50 -116 -30 -24 -97 -150 -81 -150 16 0 88 58 137 112
l50 55 -8 87 c-5 60 -14 98 -28 120 -25 41 -26 96 -2 136 l19 30 21 -39 c24
-42 28 -96 11 -134 -7 -16 -8 -46 -2 -93 l9 -69 23 50 c52 112 63 195 30 235
-11 14 -31 42 -45 62 -13 21 -42 49 -64 63 -46 29 -57 47 -66 106 l-7 43 43
-26 c44 -26 80 -76 80 -113 0 -11 18 -45 40 -75 l40 -55 0 40 c-1 47 -15 113
-36 170 -13 36 -23 44 -94 78 -56 26 -92 37 -125 37 -41 0 -52 5 -86 38 -21
22 -39 42 -39 45 0 4 25 7 57 7 53 0 58 -3 94 -40 34 -35 112 -80 139 -80 15
0 -30 69 -57 88 -13 9 -47 38 -76 64 -30 28 -69 53 -92 59 -55 15 -87 43 -107
94 l-17 43 42 -10 c55 -11 88 -36 110 -81 10 -20 35 -50 55 -67 20 -17 46 -39
57 -49 11 -10 32 -26 47 -35 l28 -18 -15 42 c-8 24 -33 62 -55 86 -37 41 -40
48 -40 103 0 33 4 62 8 65 12 7 81 -62 86 -87 3 -12 7 -51 8 -87 3 -55 12 -83
61 -182 32 -65 60 -131 63 -148 12 -58 27 -46 19 16 -10 86 -9 140 4 156 6 7
22 26 35 41 l23 29 13 -46 c13 -47 9 -124 -7 -135 -35 -23 -59 -115 -62 -237
-1 -43 -7 -99 -12 -123 -5 -24 -7 -46 -4 -50 11 -10 35 32 42 71 3 21 16 50
28 64 21 24 103 69 112 61 8 -9 -18 -98 -35 -118 -9 -11 -38 -31 -63 -44 -25
-13 -59 -41 -74 -61 -16 -21 -37 -46 -47 -56 -10 -10 -20 -29 -24 -43 -3 -14
-10 -25 -14 -25 -5 0 -11 -8 -14 -17 -3 -10 -24 -38 -47 -61 -22 -24 -39 -46
-36 -48 9 -9 55 15 74 40 34 43 82 59 142 50 l53 -9 -37 -38 c-46 -47 -78 -60
-130 -52 -47 6 -137 -23 -230 -76 -31 -17 -82 -39 -113 -49 -54 -17 -55 -18
-29 -25 16 -4 46 -3 67 4 62 18 93 13 145 -24 l49 -34 -30 -12 c-52 -22 -117
-17 -155 12 -42 32 -116 49 -213 49 -99 0 -183 -17 -220 -45 -17 -12 -41 -26
-54 -30 -25 -7 -122 14 -122 27 0 4 19 21 42 38 44 32 83 38 137 19 18 -7 47
-8 69 -4 l37 8 -48 12 c-45 12 -76 26 -162 72 -87 46 -151 65 -198 58 -52 -8
-86 6 -135 58 l-33 34 49 7 c61 8 105 -7 146 -50 17 -18 45 -36 61 -40 29 -7
29 -6 -23 51 -29 32 -61 74 -72 95 -35 68 -109 149 -158 173 -49 23 -73 58
-87 124 l-7 33 49 -21 c58 -24 78 -48 98 -115 8 -28 23 -58 34 -68 19 -18 19
-17 6 41 -8 32 -14 86 -15 121 0 114 -19 203 -51 243 -19 24 -29 48 -29 70 0
42 18 109 29 109 14 0 59 -73 65 -105 3 -16 0 -47 -7 -67 -13 -37 -11 -117 3
-101 3 4 12 28 19 53 7 25 30 77 50 115 51 94 72 162 74 240 2 60 4 66 42 105
24 24 43 36 48 31 5 -5 8 -37 5 -70z m112 -1712 c0 -111 0 -111 23 -104 67 20
96 24 137 19 121 -17 190 -96 190 -217 0 -54 -5 -70 -31 -110 -43 -65 -118
-101 -203 -97 -33 1 -96 3 -138 3 l-78 1 0 260 c0 243 -1 263 -20 294 -34 56
-29 62 50 62 l70 0 0 -111z m-920 12 l0 -90 32 -3 c47 -4 51 -35 6 -40 l-33
-3 0 -136 0 -137 33 -40 c34 -43 42 -82 17 -82 -24 0 -82 27 -103 47 -38 36
-45 66 -50 210 l-5 143 -28 0 c-22 0 -29 5 -29 20 0 16 7 20 30 20 l30 0 0 70
0 71 43 19 c23 11 45 20 50 20 4 0 7 -40 7 -89z m-606 -99 c3 -5 0 -25 -6 -46
-10 -31 -8 -44 9 -89 12 -28 36 -91 55 -139 18 -49 36 -88 40 -88 6 0 103 241
114 286 4 13 1 31 -6 39 -23 28 -4 45 50 45 41 0 50 -3 50 -18 0 -9 -9 -23
-21 -30 -24 -15 -43 -57 -115 -247 l-52 -140 -49 -3 -49 -3 -59 153 c-67 175
-87 219 -109 237 -9 8 -16 22 -16 32 0 17 8 19 79 19 44 0 82 -4 85 -8z m991
-2 c3 -5 -3 -27 -15 -49 -16 -33 -20 -58 -19 -133 2 -126 8 -148 48 -175 50
-33 105 -31 142 6 l29 29 0 129 c0 101 -3 133 -16 151 -23 34 -12 48 40 51 51
2 58 -11 31 -59 -10 -18 -14 -61 -15 -150 0 -145 -13 -184 -70 -213 -46 -24
-159 -19 -198 10 -49 35 -70 96 -78 223 -4 66 -13 127 -21 143 -7 15 -11 32
-8 37 8 13 142 13 150 0z m1343 -22 c60 -37 72 -77 72 -247 l0 -149 -82 -2
c-46 -1 -97 -1 -115 -1 -47 -1 -100 28 -124 68 -27 44 -29 117 -4 160 35 60
129 90 199 63 24 -9 26 -8 26 21 0 87 -67 118 -116 54 -22 -30 -32 -35 -66
-35 l-39 0 18 31 c39 68 152 86 231 37z m402 6 c0 -23 3 -25 23 -18 12 4 50 8
84 8 57 1 65 -2 96 -32 39 -38 57 -99 57 -198 0 -49 6 -77 25 -113 13 -27 21
-51 18 -55 -10 -10 -181 -7 -188 4 -3 5 5 29 19 53 23 38 26 56 26 131 0 89
-7 114 -43 154 -27 29 -91 31 -107 3 -5 -11 -10 -69 -10 -129 0 -97 3 -114 25
-156 14 -26 23 -52 20 -57 -4 -5 -46 -9 -96 -9 -100 0 -101 2 -69 65 17 33 20
59 20 160 0 102 -3 125 -20 153 -34 56 -29 62 50 62 69 0 70 0 70 -26z m-2410
-589 c0 -9 -9 -15 -23 -15 -34 0 -47 -19 -47 -66 0 -42 14 -63 46 -68 31 -5
28 -26 -3 -26 -47 0 -73 32 -73 91 0 40 5 55 25 74 27 28 75 34 75 10z m190
-25 c0 -33 3 -40 20 -40 17 0 20 7 20 40 0 29 4 40 15 40 12 0 15 -17 15 -95
0 -78 -3 -95 -15 -95 -11 0 -15 11 -15 40 0 33 -3 40 -19 40 -16 0 -20 -8 -23
-37 -2 -26 -8 -38 -18 -38 -12 0 -16 17 -18 79 -3 89 0 106 23 106 11 0 15
-11 15 -40z m310 25 c0 -9 -9 -15 -25 -15 -18 0 -25 -5 -25 -20 0 -15 7 -20
25 -20 32 0 34 -27 3 -32 -17 -2 -23 -10 -23 -28 0 -18 6 -26 23 -28 40 -6 26
-32 -18 -32 l-40 0 0 95 0 95 40 0 c29 0 40 -4 40 -15z m248 -50 c26 -123 26
-125 8 -125 -9 0 -16 5 -16 10 0 6 -11 10 -25 10 -14 0 -25 -4 -25 -10 0 -5
-7 -10 -16 -10 -10 0 -14 6 -11 18 3 9 13 52 22 95 13 63 20 78 33 75 12 -2
21 -22 30 -63z m257 49 c10 -11 15 -29 13 -53 -2 -28 -9 -39 -30 -49 -21 -10
-28 -20 -28 -43 0 -19 -5 -29 -15 -29 -12 0 -15 17 -15 95 l0 95 29 0 c16 0
37 -7 46 -16z m415 -46 l13 -63 13 40 c7 22 13 48 13 58 1 16 27 35 36 25 2
-2 -6 -44 -19 -93 -18 -73 -26 -90 -41 -90 -20 0 -21 4 -49 123 -13 58 -13 62
4 62 13 0 20 -14 30 -62z m330 47 c0 -8 -7 -15 -15 -15 -12 0 -15 -16 -15 -80
0 -64 -3 -80 -15 -80 -12 0 -15 16 -15 80 0 62 -3 80 -14 80 -8 0 -16 7 -20
15 -4 12 4 15 44 15 38 0 50 -4 50 -15z m190 -52 c0 -79 10 -104 34 -84 12 10
16 32 16 82 0 55 3 69 15 69 12 0 15 -15 15 -78 0 -84 -9 -106 -48 -115 -44
-10 -62 22 -62 113 0 64 3 80 15 80 12 0 15 -14 15 -67z m517 47 c14 -22 7
-74 -14 -105 -19 -28 -16 -45 7 -45 25 0 27 -20 3 -21 -30 -2 -78 3 -83 7 -2
3 0 8 5 12 19 13 64 114 58 129 -4 9 -13 13 -29 9 -26 -7 -43 12 -23 25 24 15
64 10 76 -11z m255 -27 c1 -62 2 -66 11 -80 13 -21 -3 -63 -24 -63 -10 0 -19
7 -19 15 0 10 -10 15 -30 15 -36 0 -36 3 -7 71 13 30 27 62 30 72 4 9 13 17
22 17 11 0 15 -12 17 -47z m268 39 c0 -4 -13 -46 -28 -92 -19 -56 -34 -86 -45
-88 -22 -4 -22 3 8 78 14 35 25 67 25 71 0 4 -12 9 -27 11 -14 2 -28 9 -31 16
-2 8 11 12 47 12 28 0 51 -4 51 -8z"/>
<path d="M1948 1613 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1764 946 c-44 -20 -68 -75 -61 -143 11 -106 76 -189 156 -200 35 -5
44 -2 70 24 16 16 34 49 41 72 36 134 -98 294 -206 247z"/>
<path d="M2331 814 c-59 -76 30 -246 112 -215 67 26 73 133 10 202 -44 49 -90
54 -122 13z"/>
<path d="M1220 286 c0 -22 3 -27 11 -19 8 8 8 16 0 30 -9 16 -10 14 -11 -11z"/>
<path d="M1470 334 c0 -45 24 -42 28 4 2 24 -1 32 -12 32 -12 0 -16 -10 -16
-36z"/>
<path d="M3172 305 c-15 -33 -15 -35 3 -35 10 0 15 10 15 30 0 37 -3 37 -18 5z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
